import "./Header.css"

import vk1 from '../../../img/vk1-100.png'
import vk from '../../../img/vk-100.png'
import git from '../../../img/git-100.png'
import inst from '../../../img/inst-100.png'
import tg from '../../../img/tg-100.png'
import npm from '../../../img/npm-100.png'

export default function Header() {
    return <div className='HomeMenuCommunication'>
        <div>COMMUNICATION</div>
        <div className="HomeMenuContactsLinks">
            <a href='https://vk.com/xop.enotov' target="_blank" rel="noreferrer">
                <img className="HomeMenuContactsLink" src={vk1} alt="Вконтакте"/>
            </a>
            <a href='https://vk.com/xop_enotov' target="_blank" rel="noreferrer">
                <img className="HomeMenuContactsLink" src={vk} alt="Вконтакте 2"/>
            </a>
            <a href='https://www.instagram.com/xop_enotov/' target="_blank" rel="noreferrer">
                <img className="HomeMenuContactsLink" src={inst} alt="instagram"/>
            </a>
            <a href='https://t.me/XOP_EH0T0B' target="_blank" rel="noreferrer">
                <img className="HomeMenuContactsLink" src={tg} alt="telegram"/>
            </a>
            <a href='https://github.com/XOP-EHOTOB' target="_blank" rel="noreferrer">
                <img className="HomeMenuContactsLink" src={git} alt="github"/>
            </a>
            <a href='https://www.npmjs.com/~xop_enotov' target="_blank" rel="noreferrer">
                <img className="HomeMenuContactsLink" src={npm} alt="npm"/>
            </a>
        </div>
    </div>
}