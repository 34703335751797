import './App.css';
import {
  Routes,
  Route
} from "react-router-dom";
import Shop from './panels/Shop/Shop';
import Home from './panels/Home/Home';

export default function App() {
  return <Routes>
      <Route path="shop" exact element={<Shop /> }/>
      <Route path="/" exact element={<Home/> }/>
  </Routes>
}