import "./Shop.css"
import base64 from 'base-64'

const YOOMONEY = '4100118506084210'

const letParm = (key) => {
    var a = window.location.search;
    var b = {};
    a = a.substring(1).split("&");
    for (var i = 0; i < a.length; i++) {
      let c = a[i].split("=");
    b[c[0]] = c[1];
    }
    return(b[key]);
}

export default function Shop() {
    return <div className="Shop">
        <div className="ShopWindow">
            <h1 className="ShopWindowHeader">Покупка в магазине {`${letParm("shop")}`}</h1>
            <div className="ShopWindowInfo">
                <p>Вы покупаете: <span>{`${base64.decode(letParm("text"))}`}</span></p>
                <p>Сумма покупки: <span>{`${letParm("money")}`}</span> руб.</p>
                <p>Плательщик: <a target="blank" href={`https://vk.com/id${letParm("order_id")}`}>{`id${letParm("order_id")}`}</a></p>
            </div>
            <form 
                method="POST" 
                action="https://yoomoney.ru/quickpay/confirm.xml">    
                    <input type="hidden" name="receiver" value={YOOMONEY}/>    
                    <input type="hidden" name="formcomment" value="XOP EHOTOB LLC"/>    
                    <input type="hidden" name="short-dest" value="XOP EHOTOB LLC"/>    
                    <input type="hidden" name="label" value={`3${letParm("order_id")}`}/>    
                    <input type="hidden" name="quickpay-form" value="donate"/>    
                    <input type="hidden" name="targets" value={`ID: ${letParm("order_id")}`}/>    
                    <input type="hidden" name="sum" value={letParm("money")} data-type="number"/>    
                    <input type="hidden" name="comment" value={`${letParm("shop")}, Вы совершаете покупку на ${letParm("money")} руб.`}/>     
            
                    <div>
                    <center>
                        <input type="submit" value="Оплатить" className="ShopWindowForm"/>
                    </center>
                </div>
            </form>
        </div>
    </div>
}